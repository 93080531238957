<template>
  <div>
    <about-loading v-if="loading"/>
    <div class="about">
      <div class="wrapper">
        <h1 class="about-title">About us</h1>
        <p class="about-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu </p>
        <h2 class="about-subtitle">Title 1</h2>
        <p class="about-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation <a href="#">ullamco</a> laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu </p>
        <p class="about-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis <a href="#">nostrud</a> exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu </p>
        <h2 class="about-subtitle">Title 2</h2>
        <p class="about-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation <a href="#">ullamco</a> laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu </p>
        <div class="about-img"></div>
        <p class="about-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis <a href="#">nostrud</a> exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu </p>
      </div>
    </div>
  </div>
</template>
<script>
  import AboutLoading from "../components/Loading/AboutLoading";
  export default {
    components: {AboutLoading},
    data() {
      return{
        loading: true
      }
    },
    mounted() {
      setTimeout(() => {
        this.loading = false
      }, 2000)
    }
  }
</script>
<style scoped lang="scss">
.about {
  margin: 46px 0 50px;
  @media (max-width: 768px) {
    margin: 30px 0;
  }
  .wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  &-title {
    margin: 0 0 12px;
    font-size: 40px;
    line-height: 50px;
    font-weight: bold;
    color: #000000;
    @media (max-width: 768px) {
      margin: 0 0 10px;
      font-size: 30px;
      line-height: 40px;
    }
  }
  &-text {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 28px;
    color: #333333;
    @media (max-width: 768px) {
      margin-bottom: 14px;
      font-size: 16px;
      line-height: 24px;
    }
    & + h2 {
      margin-top: 10px;
      @media (max-width: 768px) {
        margin-top: 16px;
      }
    }
  }
  &-subtitle {
    margin: 30px 0 12px;
    font-size: 30px;
    line-height: 40px;
    font-weight: bold;
    color: #000000;
    @media (max-width: 768px) {
      margin: 30px 0 10px;
      font-size: 25px;
      line-height: 32px;
    }
  }
  &-img {
    margin-bottom: 30px;
    width: 100%;
    height: 290px;
    object-fit: cover;
    border-radius: 15px;
    background: #EBEBEB;
    @media (max-width: 768px) {
      margin-bottom: 15px;
      height: 140px;
    }
  }
}
</style>
