var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('about-loading') : _vm._e(), _vm._m(0)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "about"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('h1', {
    staticClass: "about-title"
  }, [_vm._v("About us")]), _c('p', {
    staticClass: "about-text"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu ")]), _c('h2', {
    staticClass: "about-subtitle"
  }, [_vm._v("Title 1")]), _c('p', {
    staticClass: "about-text"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation "), _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("ullamco")]), _vm._v(" laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu ")]), _c('p', {
    staticClass: "about-text"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis "), _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("nostrud")]), _vm._v(" exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu ")]), _c('h2', {
    staticClass: "about-subtitle"
  }, [_vm._v("Title 2")]), _c('p', {
    staticClass: "about-text"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation "), _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("ullamco")]), _vm._v(" laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu ")]), _c('div', {
    staticClass: "about-img"
  }), _c('p', {
    staticClass: "about-text"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis "), _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("nostrud")]), _vm._v(" exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu ")])])]);
}]

export { render, staticRenderFns }