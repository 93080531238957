var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "preloader"
  }, [_c('header-loading'), _c('div', {
    staticClass: "preloader-main"
  }, _vm._l(6, function (it) {
    return _c('div', {
      key: it,
      staticStyle: {
        "margin-bottom": "30px"
      }
    }, [_c('vue-skeleton-loader', {
      staticStyle: {
        "margin-bottom": "12px"
      },
      attrs: {
        "type": "rect",
        "width": 178,
        "height": 41,
        "rounded": true,
        "radius": 5,
        "animation": "wave"
      }
    }), _vm._l(5, function (i) {
      return _c('vue-skeleton-loader', {
        key: i,
        staticClass: "w-full",
        staticStyle: {
          "margin-bottom": "5px"
        },
        attrs: {
          "type": "rect",
          "height": 15,
          "rounded": true,
          "radius": 5,
          "animation": "wave"
        }
      });
    })], 2);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }