<template>
  <div class="preloader">
    <header-loading/>
    <div class="preloader-main">
      <div style="margin-bottom: 30px;" v-for="it in 6" :key="it">
        <vue-skeleton-loader
          style="margin-bottom: 12px;"
          type="rect"
          :width="178"
          :height="41"
          :rounded="true"
          :radius="5"
          animation="wave"
        />
        <vue-skeleton-loader
          style="margin-bottom: 5px;"
          v-for="i in 5"
          :key="i"
          type="rect"
          :height="15"
          :rounded="true"
          :radius="5"
          class="w-full"
          animation="wave"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import VueSkeletonLoader from 'skeleton-loader-vue';
  import HeaderLoading from "./HeaderLoading";
  export default {
    name: 'AboutLoading',
    components: {HeaderLoading, VueSkeletonLoader},
    mounted() {
      document.body.style.overflowY = 'hidden'
      document.body.style.width = '99%'
    },
    beforeDestroy () {
      document.body.style.overflowY = 'auto'
      document.body.style.width = '100%'
    },
  }
</script>
<style scoped lang="scss">


  body, html, .preloader {
    z-index: 9999;
    background: #F5F5F5;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;

    .w-full {
      width: 100% !important;
    }

    .mb-20 {
      margin-bottom: 20px;
    }

    .mt-37 {
      margin-top: 37px;
    }

    .justify-between {
      justify-content: space-between;
    }

    .mrl-35 {
      margin-right: 35px;
      margin-left: 35px;
    }

    .loader-bg {
      background: #e2e2e2;
      padding-bottom: 12px;
      padding-top: 12px;
    }

    .flex {
      display: flex;
    }

    .mr-2 {
      margin-right: 8px;
    }

    .mr-30 {
      margin-right: 30px;
    }

    &-main {
      background: #e8e8e8;
      padding: 45px 18% 184px;

      @media (max-width: 768px) {
        padding: 45px 20px 184px;
      }


    }
  }

</style>
